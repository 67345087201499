<template>
  <div class="card">
    <div class="card-header">
      <a-button class="mr-2" @click="showModalNewProfStandard" :disabled="!$auth.isLoggedIn()">
        <template #icon><i class="fa fa-plus" /></template>
      </a-button>
      <a-button
        class="mr-2"
        :disabled="(selectedRowKeys.length != 1)||(!$auth.isLoggedIn())"
        @click="editEntity(selectedRows)"
      >
        <template #icon><i class="fa fa-pencil" /></template>
      </a-button>
      <a-button
        class="mr-2"
        :disabled="(selectedRowKeys.length == 0)||(!$auth.isLoggedIn())"
        @click="deleteEntity(selectedRowKeys)"
      >
        <template #icon><i class="fa fa-trash" /></template>
      </a-button>
    </div>

    <a-table
      :dataSource="gridData"
      :columns="gridColumns"
      :scroll="scroll"
      :customRow="rowClick"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      rowKey="id"
      :class="gridData.length < 5 ? 'hide-native-scrollbar' : ''"
    />

    <a-modal
      width="100"
      v-model:visible="visibleProfStandard"
      title="Профессиональный стандарт"
      @ok="handleOkProfStandard"
    >
      <a-form ref="ProfStandardForm" :model="ProfStandardFormState">
        <a-form-item label="Наименование">
          <a-input v-model:value="ProfStandardFormState.ProfStandardTitle" :disabled="!$auth.isLoggedIn()"/>
        </a-form-item>
        <a-form-item label="Код">
          <a-input v-model:value="ProfStandardFormState.ProfStandardCode" :disabled="!$auth.isLoggedIn()"/>
        </a-form-item>
        <a-form-item label="URL">
          <a-input v-model:value="ProfStandardFormState.ProfStandardUrl" :disabled="!$auth.isLoggedIn()"/>
        </a-form-item>
        <!-- <a-form-item label="SampleProgram SPID">
          <a-input v-model:value="ProfStandardFormState.ProfStandardSPID" :disabled="!$auth.isLoggedIn()"/>
        </a-form-item> -->
      </a-form>
    </a-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";

export default {
  name: "ProfStandard",
  props: {
    SampleProgramID: {
      type: Array
    },
    scroll: {
      type: Object,
      default () {
        return {
          x: 'calc(100%)',
          y: '100%'
        }
      }
    }
  },
  data() {
    return {
      gridColumns: [
        { dataIndex: "id", title: "ID", width: 80 },
        //{ dataIndex: "spId", title: "SPID" },
        { dataIndex: "code", title: "Код" },
        { dataIndex: "title", title: "Наименование" },
        { dataIndex: "url", title: "URL" },
      ],
      gridData: [],
      visibleProfStandard: false,
      thisProfStandard: [],
      ProfStandardFormState: {
        ProfStandardID: undefined,
        ProfStandardSPID: undefined,
        ProfStandardCode: undefined,
        ProfStandardTitle: undefined,
        ProfStandardUrl: undefined,
      },
      selectedRowKeys: [],
      selectedRows: [],
    };
  },
  watch: {
    SampleProgramID: {
      handler() {
        this.reload();
      },
      function() {},

      immediate: true,
    },
  },
  methods: {
    async reload() {
      if (this.SampleProgramID == undefined) {
        this.gridData = (await axios.get("profstandard")).data;
      } else {
        if (this.SampleProgramID == -1) {
          this.gridData = [];
        } else {
          this.gridData = (
            await axios.get(`profstandard/slaveobject/${this.SampleProgramID}`)
          ).data;
        }
      }
    },
    rowClick(record, index) {
      return {
        onClick: () => {
          console.log(record, index, 1111);
          this.thisProfStandard = record;
          this.ProfStandardFormState.ProfStandardID = record.id;
          this.ProfStandardFormState.ProfStandardSPID = record.spId;
          this.ProfStandardFormState.ProfStandardCode = record.code;
          this.ProfStandardFormState.ProfStandardTitle = record.title;
          this.ProfStandardFormState.ProfStandardUrl = record.url;
          console.log(this.thisProfStandard);
          this.showModalProfStandard();
        },
      };
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    showModalProfStandard() {
      this.visibleProfStandard = true;
    },
    clearForm() {
      this.ProfStandardFormState.ProfStandardID = undefined;
      this.ProfStandardFormState.ProfStandardSPID = undefined;
      this.ProfStandardFormState.ProfStandardCode = undefined;
      this.ProfStandardFormState.ProfStandardTitle = undefined;
      this.ProfStandardFormState.ProfStandardUrl = undefined;
    },
    showModalNewProfStandard() {
      this.clearForm();
      if (this.SampleProgramID != undefined)
        this.ProfStandardFormState.ProfStandardSPID = this.SampleProgramID;
      this.visibleProfStandard = true;
    },
    async handleOkProfStandard() {
      console.log("profstandard.");
      this.visibleProfStandard = false;
      if (this.ProfStandardFormState.ProfStandardTitle !== undefined) {
        if (this.ProfStandardFormState.ProfStandardID == undefined) {
          try {
            await axios.post("profstandard", {
              title: this.ProfStandardFormState.ProfStandardTitle,
              code: this.ProfStandardFormState.ProfStandardCode,
              spid: this.ProfStandardFormState.ProfStandardSPID,
              url: this.ProfStandardFormState.ProfStandardUrl,
            });
          } finally {
            this.clearForm();
            await this.reload();
          }
        } else {
          console.log("profstandard");
          console.log({
            id: this.ProfStandardFormState.ProfStandardID,
            title: this.ProfStandardFormState.ProfStandardTitle,
          });
          try {
            await axios.put("profstandard", {
              id: this.ProfStandardFormState.ProfStandardID,
              spid: this.ProfStandardFormState.ProfStandardSPID,
              title: this.ProfStandardFormState.ProfStandardTitle,
              code: this.ProfStandardFormState.ProfStandardCode,
              url: this.ProfStandardFormState.ProfStandardUrl,
            });
          } finally {
            this.clearForm();
            await this.reload();
          }
        }
      } else {
        return;
      }
    },
    async deleteEntity(selectedRowKeys) {
      console.log(selectedRowKeys);
      try {
        await axios.delete("profstandard", {
          headers: { "Content-Type": "application/json; charset=utf-8" },
          data: selectedRowKeys,
        });
      } finally {
        selectedRowKeys = undefined;
        await this.reload();
      }
    },
    async editEntity(selectedRows) {
      console.log(selectedRows);
      this.ProfStandardFormState.ProfStandardID = selectedRows[0].id;
      this.ProfStandardFormState.ProfStandardSPID = selectedRows[0].spId;
      this.ProfStandardFormState.ProfStandardTitle = selectedRows[0].title;
      this.ProfStandardFormState.ProfStandardCode = selectedRows[0].code;
      this.ProfStandardFormState.ProfStandardUrl = selectedRows[0].url;
      this.showModalProfStandard();
    },
  },
  async created() {
    await this.reload();
  },
};
</script>
